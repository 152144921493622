import './hmr.js';
import page from 'page';
import 'bootstrap';

import { jasenlista } from './jasenlista.js';

import jasenmaksut from './jasenmaksut.js';
import kurssi from './kurssi.js';
import pankkitapahtumat from './pankkitapahtumat.js';

page('/jasen/jasenlista', () => jasenlista({
    elementId: 'form_jasen',
    value: 'url',
    onSelectItem: function({value}) { window.location = value; }
}));
page('/jasen/hyki/jasenmaksut', jasenmaksut);
page('/jasen/hyki/kurssit/:id', kurssi);
page('/jasen/hyki/tilitapahtuma/lisaa', () => jasenlista({
    elementId: 'form_jasen',
    value: 'id',
    onSelectItem: ({value}) => document.getElementById('form_jasen').value = value
}));
page('/jasen/hyki/pankkitapahtumat/lisaa', pankkitapahtumat);

page({
    click: false,
    popstate: false
});
