import { Autocomplete } from '../lib/bootstrap5-autocomplete/autocomplete.js';
import { Bloodhound } from '../lib/bloodhound.js';
import { ascend, descend, prop } from 'ramda';

function composeComparers(fns) {
    return (a, b) => {
        var result = 0;
        var i = 0;

        while (result === 0 && i < fns.length) {
            result = fns[i](a, b);
            i += 1;
        }

        return result;
    };
}

function identify(datum) {
    return datum.id;
}

function datumTokenizer(datum) {
    return [datum.id, ...Bloodhound.tokenizers.whitespace(datum.name)];
}

export function jasenlista(options) {
    if (!options.elementId && !options.element) throw new Error('jasenlista options: no element nor elementId set');
    if (!options.value) throw new Error('jasenlista options value not set');

    const sorter = composeComparers([
        descend(prop('active')),
        ascend(prop('full_name')),
    ]);

    const localJsonData = JSON.parse(document.getElementById('memberlist-data').textContent);

    const engine = new Bloodhound({
        local: localJsonData,
        identify,
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        datumTokenizer,
        sorter
    });

    const datasrc = [];

    function showMatches(datums) {
        datasrc.length = 0;
        datasrc.push(...datums);
    }

    const element = options.element || document.getElementById(options.elementId);
    new Autocomplete(element, {
        data: datasrc,
        maximumItems: 20,
        label: 'name',
        value: options.value,
        highlightTyped: true,
        onInput: (query) => engine.search(query, showMatches),
        onSelectItem: options.onSelectItem || console.log,
        className: (datum) => datum.active ? [] : ['text-muted', 'fst-italic']
    });
}
