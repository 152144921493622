export default function() {
    for (const button of document.querySelectorAll("#course-partisipant-actions button")) {
        button.addEventListener('click', async function (e) {
            var member = e.target.dataset['memberId'];
            var course = e.target.dataset['courseId'];

            let url = '#';
            switch (e.target.name) {
            case 'OK':
                url = `/jasen/api/hyki/${member}/kurssimaksu`;
                break;
            case 'DNF':
                if (!confirm("Vahvista kurssin keskeytys.")) return;
                url = `/jasen/api/hyki/${member}/erota`;
                break;
            }

            const res = await fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    "member": member,
                    "course": course
                })
            });

            if (res.ok) {
                const okNode = document.createElement('span');
                okNode.classList.add('ms-auto');
                okNode.textContent = 'OK';
                e.target.parentNode.parentNode.replaceChild(okNode, e.target.parentNode);
            } else {
                e.target.textContent = "Epäonnistui...";
                e.target.classList.remove("btn-success");
                e.target.classList.add("btn-danger");
            }
        });
    }
}