export default function() {
    for (const form of document.querySelectorAll("form[name=expelMemberForm]")) {
        form.addEventListener('submit', async function (event) {
            event.preventDefault();
            const { ok } = await fetch(form.action, { method: "POST" });
            if (ok) {
                const button = form.querySelector('button');
                button.innerText = 'Erotettu';
                button.classList.remove("btn-danger");
                button.classList.add("btn-success");
            }
        });
    }
}