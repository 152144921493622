import { jasenlista } from './jasenlista.js';

export default function() {
    document.querySelectorAll('form[name=bank]').forEach((el) => {
        const memberInputElement = el.querySelector('input[type=text]');
        jasenlista({
            element: memberInputElement,
            value: 'id',
            onSelectItem: ({value}) => memberInputElement.value = value
        });

        el.addEventListener('submit', onSubmit);
    });
}

async function onSubmit(event) {
    event.preventDefault();

    const submit = event.target.querySelector("button[type=submit]");
    submit.disabled = true;

    try {
        const res = await fetch(event.target.action, {
            method: 'POST',
            body: new FormData(event.target),
        });

        if (res.status !== 204 && res.status !== 201) {
            throw Error(res.statusText);
        }

        if (res.status === 201) {
            const link = document.createElement('a');
            link.href = res.headers.get('Location');
            link.text = 'Näytä';
            link.classList.add('btn', 'btn-link');

            submit.replaceWith(link);
        }

        submit.remove();
    } catch (e) {
        console.error(e);
        alert('Error occurred - check console');
    } finally {
        submit.disabled = false;
    }
}